import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const VideoPlayer = ({ cdn_id }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const videoSrc = `https://iframe.mediadelivery.net/embed/350891/${cdn_id}?preload=true&responsive=true`;

  useEffect(() => {}, [cdn_id]);

  return (
    <div className="relative w-full">
      <iframe
        title={`Video Player - ${cdn_id}`}
        src={videoSrc}
        loading="lazy"
        className="w-full aspect-[16/9] max-h-[calc(100vh-60px)]"
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        allowFullScreen
      ></iframe>
    </div>
  );
};

VideoPlayer.propTypes = {
  cdn_id: PropTypes.string.isRequired,
};

export default VideoPlayer;
