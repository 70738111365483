import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const ProfilePage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect, isLoading, user } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, isLoading]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-50 text-gray-800">
      {/* Left sidebar */}
      <aside className="w-64 flex-shrink-0 bg-white shadow-md">
        <div className="p-4 border-b">
          <h2 className="text-xl font-bold">Account</h2>
          <p className="text-sm text-gray-500">Manage your account info.</p>
        </div>
        <nav className="mt-4">
          {/* Active link styling for "Profile" */}
          <a
            href="#profile"
            className="block py-2 px-4 bg-gray-100 text-gray-900 font-medium"
          >
            Profile
          </a>
          {/* Inactive link styling for "Security" */}
          <a
            href="#security"
            className="block py-2 px-4 text-gray-600 hover:bg-gray-100 hover:text-gray-900"
          >
            Security
          </a>
        </nav>
      </aside>

      {/* Main content */}
      <main className="flex-grow p-6 overflow-auto">
        {/* Full-width “bubble” container */}
        <div className="w-full h-full bg-white rounded-lg shadow p-6">
          {/* Title */}
          <h2 className="text-2xl font-bold mb-4">Profile details</h2>
          <hr className="mb-6" />

          {/* Profile row */}
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <img
                className="w-16 h-16 rounded-full object-cover"
                src={user?.picture}
                alt="Profile"
              />
              <div>
                <p className="text-xl font-semibold">
                  {user?.name || "John Doe"}
                </p>
              </div>
            </div>
            <button className="text-blue-600 hover:text-blue-800">
              Edit profile
            </button>
          </div>
          <hr className="mb-6" />

          {/* Email addresses */}
          <div className="mb-8">
            <h3 className="text-lg font-semibold mb-2">Email addresses</h3>
            <div className="mb-3">
              {user?.email ? (
                <div className="flex items-center space-x-2">
                  <p>{user.email}</p>
                  <span className="text-xs text-white bg-gray-400 rounded-full px-2 py-1">
                    Primary
                  </span>
                </div>
              ) : (
                <p>No primary email on file.</p>
              )}
            </div>
            {/* Additional emails could be mapped here if you store them */}
            <button className="text-blue-600 hover:text-blue-800 text-sm">
              + Add email address
            </button>
          </div>
          <hr className="mb-6" />

          {/* Phone number */}
          <div className="mb-8">
            <h3 className="text-lg font-semibold mb-2">Phone number</h3>
            {/* Hard-coded placeholder here; replace with your data if available */}
            <div className="flex items-center space-x-2 mb-3">
              <p>+1 (555) 123-4567</p>
              <span className="text-xs text-white bg-gray-400 rounded-full px-2 py-1">
                Primary
              </span>
            </div>
            <button className="text-blue-600 hover:text-blue-800 text-sm">
              + Add phone number
            </button>
          </div>
          <hr className="mb-6" />

          {/* Connected accounts */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Connected accounts</h3>
            {/* Example of a connected account; adapt or remove as needed */}
            <div className="flex items-center space-x-2 mb-3">
              <p>Google • example@email.com</p>
            </div>
            <button className="text-blue-600 hover:text-blue-800 text-sm">
              + Connect account
            </button>
          </div>
          <hr className="mb-6" />
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;
