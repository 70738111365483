import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";

import Layout from "./pages/Layout";
import Catalog from "./pages/Catalog";
import Stream from "./pages/Stream";
import About from "./pages/About";
import ProfilePage from "./pages/ProfilePage"; // Ensure correct import
import Home from "./pages/Home";
import LogoutButton from "./components/LogoutButton";
import LoginButton from "./components/LoginButton";
import TestSupabase from "./pages/test-supabase";

const auth0URL = process.env.REACT_APP_AUTH0_URL;
const auth0Key = process.env.REACT_APP_AUTH0_CLIENT_KEY;

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="stream" element={<Stream />} />
        <Route path="/stream/:id" element={<Stream />} />
        <Route path="about" element={<About />} />
        <Route path="catalog" element={<Catalog />} />
        <Route path="profile" element={<ProfilePage />} />{" "}
        <Route path="*" element={<Home />} />
        <Route path="/api/supabase" element={<TestSupabase />} />
      </Route>
    </Routes>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <BrowserRouter>
      <App />
    </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
