import React from "react";
import { Link, useLocation } from "react-router-dom";
import trclogo from "../assets/images/TRC-short-white.png";
import ProfileHeader from "./ProfileHeader";

const Header = () => {
  const location = useLocation();
  const isHomeOrCatalog =
    location.pathname === "/" || location.pathname === "/catalog";

  return (
    <header
      className={`w-full h-12 text-slate-50 flex items-center px-8 gap-4 ${
        isHomeOrCatalog
          ? "bg-gradient-to-b from-slate-950 from-10% to-transparent fixed top-0 z-50"
          : "relative bg-slate-900"
      }`}
    >
      <a href="/catalog">
        <img src={trclogo} alt="logo" className="h-8" />
      </a>
      <ul className="hidden md:flex gap-4 ml-auto items-center">
        <li>
          <Link
            to="/"
            className={`${
              location.pathname === "/" ? "text-red-500" : "text-slate-50"
            } hover:text-red-400`}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/stream"
            className={`${
              location.pathname === "/stream" ? "text-red-500" : "text-slate-50"
            } hover:text-red-400 hidden`}
          >
            Live
          </Link>
        </li>
        <li>
          <Link
            to="/catalog"
            className={`${
              location.pathname === "/catalog"
                ? "text-red-500"
                : "text-slate-50"
            } hover:text-red-400`}
          >
            Catalog
          </Link>
        </li>
      </ul>
    </header>
  );
};

export default Header;
