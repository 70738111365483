import PropTypes from "prop-types";
import React from "react";
import buildURL from "../../utils/urlBuilder";

function CatalogItem({
  id,
  cdn_id,
  title,
  subtitle,
  thumbnail,
  preview,
  date,
  className,
}) {
  // Format date nicely
  const dateFormatted = new Date(date).toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });

  // Build URLs - handle both URL and CDN-based thumbnails
  const thumbnail_url = thumbnail?.startsWith('http') ? thumbnail : buildURL(cdn_id, "thumbnail", thumbnail);
  const preview_url = buildURL(cdn_id, "preview");

  const isUpcoming = new Date(date) > new Date();

  return (
    <div
      className={`
        relative 
        bg-gray-800 
        rounded-lg 
        shadow-md 
        overflow-hidden 
        transform 
        transition 
        duration-300 
        hover:scale-105 
        hover:shadow-xl
        cursor-pointer 
        flex-shrink-0 
        w-64
        group
        ${className || ""}
      `}
    >
      <a href={`/stream/${id}`}>
        <div className="relative w-full pb-[56.25%] overflow-hidden">
          {/* Static Thumbnail */}
          <img
            src={thumbnail_url}
            alt={`${title} Static Thumbnail`}
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${isUpcoming ? '' : 'group-hover:opacity-0'}`}
            fetchpriority="high"
          />
          {/* Animated Preview */}
          {!isUpcoming && preview && (
            <img
              src={preview_url}
              alt={`${title} WebP Preview`}
              className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              fetchpriority="low"
            />
          )}

          {/* Tag in the bottom-right corner */}
          <div className={`absolute bottom-2 right-2 text-white text-xs px-2 py-1 rounded-md shadow-md ${
            isUpcoming ? 'bg-blue-600/80' : 'bg-gray-800/60'
          }`}>
            {isUpcoming ? "Upcoming" : "Free"}
          </div>
        </div>

        {/* Text content */}
        <div className="p-3">
          <h2 className="text-white font-semibold line-clamp-2 text-sm md:text-base lg:text-lg">
            {title}
          </h2>
          <h3 className="text-gray-100 font-light">{subtitle}</h3>
          <h3 className="text-gray-300 font-light text-sm">{dateFormatted}</h3>
        </div>
      </a>
    </div>
  );
}

CatalogItem.propTypes = {
  id: PropTypes.string.isRequired,
  cdn_id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  thumbnail: PropTypes.string.isRequired,
  preview: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
};

export default CatalogItem;
