import { useParams } from "react-router-dom";
import eventLogo from "../assets/images/lbra_Logo.png";
import Sidebar from "../components/Sidebar";
import VideoPlayer from "../components/VideoPlayer";
import LiveCountdown from "../components/LiveCountdown";
import { supabase } from "../utils/supabase";
import { useEffect, useState } from "react";

function Stream() {
  const { id } = useParams();
  const defaultId = "fbe7358f-3c65-484f-8131-ac78bd29c93b";
  const idToUse = id || defaultId;

  const [videoInfo, setVideoInfo] = useState({
    title: "Loading...",
    description: "Please wait while we fetch the video details.",
    date: "",
    eventName: "",
    venue: "",
    cdn_id: "",
    event_results: { schedule: [] },
  });

  const isUpcoming = new Date(videoInfo.date) > new Date();

  useEffect(() => {
    async function fetchVideoInfo() {
      try {
        const { data, error } = await supabase
          .from("catalog")
          .select(`id, name, date, cdn_id, event_results, event(name, venue)`)
          .eq("id", idToUse)
          .single();

        if (error) {
          console.error("Error fetching video details:", error.message);
          setVideoInfo({
            title: "Error",
            description: "Not Found",
            date: "",
            eventName: "",
            venue: "",
            cdn_id: "",
            event_results: { schedule: [] },
          });
          return;
        }

        if (data) {
          setVideoInfo({
            title: data.name || "Untitled",
            description: `Event: ${data.event?.name || "Unknown"} - ${
              data.event?.venue || "Unknown Venue"
            }`,
            date: data.date,
            dateFormatted: new Date(data.date).toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
              timeZone: "UTC",
            }),
            eventName: data.event?.name || "Unknown Event",
            venue: data.event?.venue || "Unknown Venue",
            cdn_id: data.cdn_id,
            event_results: data.event_results || { schedule: [] },
          });
        }
      } catch (err) {
        console.error("Unexpected error:", err);
        setVideoInfo({
          title: "Error",
          description: "Something went wrong while fetching video details.",
          date: "",
          eventName: "",
          venue: "",
          cdn_id: "",
          event_results: { schedule: [] },
        });
      }
    }

    fetchVideoInfo();
  }, [idToUse]);

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      <div className="flex-1 flex flex-col">
        {isUpcoming ? (
          <div className="h-full">
            <div className="relative" style={{ paddingTop: '56.25%' }}>
              <div className="absolute inset-0">
                <LiveCountdown eventDate={videoInfo.date} title={videoInfo.title} />
              </div>
            </div>
            <div className="flex-1" />
          </div>
        ) : (
          <>
            <VideoPlayer cdn_id={videoInfo.cdn_id} />
            <div className="w-full p-3 text-white">
              <div className="flex gap-4 items-center ml-2">
                <div>
                  <h1 className="text-2xl font-light">{videoInfo.title}</h1>
                  <p className="font-thin">
                    {videoInfo.dateFormatted} | {videoInfo.venue}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Sidebar lineupData={videoInfo.event_results.schedule} />
    </div>
  );
}

export default Stream;
