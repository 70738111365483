import React, { Component, useEffect, useState } from "react";
import Hero from "../components/Hero";
import CatalogRow from "../components/catalog/CatalogRow";
import { supabase } from "../utils/supabase";
import CatalogGallery from "../components/catalog/CatalogGallery";

function Catalog() {
  const [featuredItems, setFeaturedItems] = useState([]);
  const [upcomingItems, setUpcomingItems] = useState([]);
  const [wiraItems, setWira] = useState([]);
  const [catalogItems, setCatalogitems] = useState([]);
  const [hocrItems, setHOCR] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data: featured, error: featuredError } = await supabase
        .from("catalog")
        .select(
          `id,
          name,
          subtitle,
          date,
          cdn_id,
          thumbnail_file_name`
        )
        .eq("featured", true)
        .order("date", { ascending: false })
        .limit(20);

      if (featuredError) console.error(featuredError);
      setFeaturedItems(featured);

      const { data: upcoming, error: upcomingError } = await supabase
        .from("catalog")
        .select(
          `id,
          name,
          subtitle,
          date,
          cdn_id,
          thumbnail_file_name`
        )
        .gt("date", new Date().toISOString())
        .order("date", { ascending: true })
        .limit(20);

      if (upcomingError) console.error(upcomingError);
      setUpcomingItems(upcoming);

      const { data: catalog, error: catalogError } = await supabase
        .from("catalog")
        .select(
          `id,
            name,
            subtitle,
            date,
            cdn_id,
            thumbnail_file_name`
        )
        .order("date", { ascending: false });

      if (catalogError) console.error(catalogError);
      setCatalogitems(catalog);

      const { data: wiraFeatured, error: wiraError } = await supabase
        .from("event")
        .select(
          `
          id,
          name,
          catalog (
            id,
            name,
            subtitle,
            date,
            cdn_id,
            thumbnail_file_name
          )
        `
        )
        .eq("id", "e71eb088-ab7b-4954-bfcd-c1ebff883d6c")
        .limit(20);

      if (wiraError) {
        console.error(wiraError);
        return;
      }
      

      const deriveWIRA = wiraFeatured
        .flatMap((event) => event.catalog)
        .sort((b, a) => new Date(b.date) - new Date(a.date));
      setWira(deriveWIRA);

      const { data: hocrFeatured, error: hocrError } = await supabase
        .from("event")
        .select(
          `
        id,
        name,
        catalog (
          id,
          name,
          subtitle,
          date,
          cdn_id,
          thumbnail_file_name
        )
      `
        )
        .eq("id", "cd2ec3d7-36cf-44a5-a335-ab79e0a7f06b")
        .gte("catalog.date", "2024-01-01")
        .lte("catalog.date", "2024-12-31")
        .limit(20);

      if (hocrError) {
        console.error(hocrError);
        return;
      }

      const deriveHOCR2024 = hocrFeatured
        .flatMap((event) => event.catalog)
        .sort((b, a) => new Date(a.date) - new Date(b.date));
      setHOCR(deriveHOCR2024);
    }

    fetchData();
  }, []);

  return (
    <div className="bg-gray-950">
      <div className="">
        <Hero />
      </div>
      <div className="w-full h-auto py-8 px-8 mt-4 flex flex-col bg-gradient-to-r from-indigo-500 to-gray-950">
        <p className="text-gray-200 font-thin">Welcome to the Rowing Channel</p>
        <h1 className="pt-4 text-white font-bold text-2xl">
          Enjoy free access to The Rowing Channel through March 2025
        </h1>
        <p className="text-white font-light pt-2">
          The Rowing Channel is building a platform for the community, by the
          community. While we're filling up our catalog, enjoy full access to
          previous events.
        </p>
      </div>
      <div className="">
      <CatalogRow title="Upcoming" items={upcomingItems} /> 
        <CatalogRow title="Featured" items={featuredItems} />
        <CatalogRow title="10 Years of WIRA" items={wiraItems} />
        <CatalogRow title="2024 Head of the Charles" items={hocrItems} />
        <CatalogGallery title="Full Library" items={catalogItems} />
      </div>
    </div>
  );
}

export default Catalog;
