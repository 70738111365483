import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function LiveCountdown({ eventDate, title }) {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  // Format date nicely
  const dateFormatted = new Date(eventDate).toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = new Date(eventDate) - new Date();
      
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft();

    return () => clearInterval(timer);
  }, [eventDate]);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex-1 flex flex-col items-center justify-center py-12 px-4">
        <div className="text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">{title}</h1>
          <h2 className="text-2xl md:text-3xl text-gray-300 font-light">{dateFormatted}</h2>
        </div>
        
        <div className="w-full max-w-4xl mt-12">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
            <div className="bg-gray-800/80 p-4 md:p-6 rounded-lg">
              <div className="text-3xl md:text-5xl font-bold text-white mb-2">{timeLeft.days}</div>
              <div className="text-gray-400 text-sm md:text-lg">Days</div>
            </div>
            <div className="bg-gray-800/80 p-4 md:p-6 rounded-lg">
              <div className="text-3xl md:text-5xl font-bold text-white mb-2">{timeLeft.hours}</div>
              <div className="text-gray-400 text-sm md:text-lg">Hours</div>
            </div>
            <div className="bg-gray-800/80 p-4 md:p-6 rounded-lg">
              <div className="text-3xl md:text-5xl font-bold text-white mb-2">{timeLeft.minutes}</div>
              <div className="text-gray-400 text-sm md:text-lg">Minutes</div>
            </div>
            <div className="bg-gray-800/80 p-4 md:p-6 rounded-lg">
              <div className="text-3xl md:text-5xl font-bold text-white mb-2">{timeLeft.seconds}</div>
              <div className="text-gray-400 text-sm md:text-lg">Seconds</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LiveCountdown.propTypes = {
  eventDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LiveCountdown;